.profile {
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  font-size: fontsize(p);
  padding-bottom: 20px;
  border-bottom: 3px solid color(grey, bg);

  .edit {
    position: absolute;
    top: 0;
    right: 0;
    color: color(accent);

    a {
      color: color(accent);
    }
  }

  .avatar {
    width: 150px;
    height: 150px;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
    margin-right: 25px;

    &.placeholder {
      border: 2px solid color(accent);
    }
  }

  .inner {
    p {
      margin: 0;
      line-height: 1;

      &.name {
        font-size: fontsize(h1);
        margin-bottom: 15px;
      }

      &.co {
        font-size: fontsize(h3);
      }
    }
  }
}

.list.profile-list {
  margin-top: 0;
  border-bottom: 3px solid color(grey, bg);

  .communities {
    ul {
      display: flex;
      flex-wrap: wrap;
      line-height: 1;

      li {
        &:first-of-type {
          a {
            padding-left: 0;
          }
        }

        &:not(:last-of-type) {
          border-right: 2px solid color(black);
        }

        a {
          color: color(accent);
          padding: 0 10px;
        }
      }
    }
  }
}
