.dropdown {
  display: table;
  margin: 0 auto;
  text-transform: uppercase;
  position: relative;
  @include dropDownStyle;
  padding: 5px 30px 5px 10px;

  a {
    text-decoration: none;
  }

  [data-toggle="dropdown"] {
    position: relative;
    display: flex;
    align-items: center;
  }

  .icon-arrow {
    position: absolute;
    display: block;
    font-size: 0.7em;
    top: 4px;
    right: -17px;
    color: #fff;
    border: solid color(accent);
    border-width: 0 2px 2px 0;
    display: inline-block;
    transform: rotate(45deg);
    padding: 3px;
    transition: 0.5s all;
  }

  &.active {
    .icon-arrow {
      transform: rotate(45deg + 180deg);
    }
  }

  .dropdown-menu {
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s;
    position: absolute;
    top: 26px;
    left: -1px;
    width: calc(100% + 2px);
    background: color(white);
    opacity: 0;
    border-bottom: 1px solid color(accent);
    border-right: 1px solid color(accent);
    border-left: 1px solid color(accent);
    border-radius: 0 0 $borderRadius $borderRadius;
    z-index: 99;

    li {
      padding: 0;

      &:not(:last-of-type) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      }

      &.selected {
        display: none;
      }

      a {
        display: block;
        padding: 5px 10px;
        transition: all 0.5s;

        &:hover {
          color: color(accent);
        }
      }
    }
  }

  .active {
    opacity: 1;
    max-height: 1000px;
  }
}
