.file-upload {
  margin-top: 20px;

  .progress-bar {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .bar {
      width: calc(100% - 50px);
      height: 20px;
      border-radius: $borderRadius;
      border: 2px solid color(grey, bg);
      position: relative;
      margin: 0 10px 0 0;
      overflow: hidden;

      .progress {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: color(accent);
      }
    }
  }
}
