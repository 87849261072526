.cb {
  display: flex;
  margin-right: 15px;

  label {
    display: flex;
    position: relative;
    padding: 0;
    line-height: 1;
    text-transform: uppercase;
    cursor: pointer;

    transition: all 0.2s;

    &:before {
      content: "";
      position: relative;
      width: 15px;
      height: 15px;
      margin-right: 5px;
      background: color(white);
      border: 1px solid color(black);
      transition: 0.3s ease-in-out;
    }
  }

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;

    &:checked + label {
      &:before {
        border-color: color(accent);
        background-color: color(accent);
      }
    }
  }

  &.house {
    label {
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 10px;
        height: 10px;
        transform: rotate(45deg) translate(0.8px, -2.5px);
        background: color(white);
        border: 1px solid color(black);
        transition: 0.3s ease-in-out;
      }

      &:before {
        height: 10px;
        z-index: 9;
        transform: translate(0, 5px);
        border-top: none;
      }
    }

    input[type="checkbox"] {
      &:checked + label {
        &:after {
          border-color: color(accent);
          background-color: color(accent);
        }
      }
    }
  }
}

.checkmark {
  position: relative;
  width: 15px !important;
  height: 15px !important;
  margin: 0;

  input {
    width: 15px !important;
    height: 15px !important;
    margin: 0;
    opacity: 0;
  }

  &:after {
    content: "";
    position: absolute;
    background-image: url("/images/checkmark.png");
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    opacity: 0;
    pointer-events: none;

    transition: all 0.5s;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    background: color(white);
    border: 1px solid color(accent);
    border-radius: $borderRadius;
    pointer-events: none;

    transition: all 0.5s;
  }

  &.checked {
    &:after {
      opacity: 1;
    }
    &:before {
      background: color(accent);
    }
  }

  &[disabled] {
    opacity: 0.5;
  }
}
