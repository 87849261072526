//Overwrite defaults
* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin: 0;
    padding: 0;
  }
}

p,
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}

//Responsive switches
.desktop {
  display: block;

  @media (max-width: bp(sm)) {
    display: none;
  }
}

.mobile {
  display: none;

  @media (max-width: bp(sm)) {
    display: block;
  }
}

//Structural components
section,
.full {
  width: 100%;
  position: relative;
}

.lrg-cont {
  width: 1300px;
  margin: 0 auto;

  @media (max-width: bp(lg-desktop)) {
    width: 96%;
    margin: 0 2%;
  }
}

.cont {
  width: 1125px;
  margin: 0 auto;
  position: relative;
  display: block;

  @media (max-width: bp(sm-desktop)) {
    width: 90%;
    margin: 0 5% 0 5%;
  }
}

.float-cont {
  width: 1125px;
  float: left;
  margin: 0 calc((100% - 1125px) / 2);

  @media (max-width: bp(sm-desktop)) {
    width: 90%;
    margin: 0 5% 0 5%;
  }
}

.small-cont {
  width: 950px;
  margin: 0 auto;

  @media (max-width: bp(tablet)) {
    width: 90%;
    margin: 0 5% 0 5%;
  }
}

.xs-cont {
  width: 768px;
  margin: 0 auto;

  @media (max-width: bp(tablet)) {
    width: 90%;
    margin: 0 5% 0 5%;
  }
}

.half {
  width: 50%;
  float: left;

  @media (max-width: bp(tablet)) {
    width: 100%;
  }
}

//Responsive video frame
.responsive-video {
  width: 100%;
  float: left;

  .video-size-frame {
    height: 0;
    padding-bottom: 56.3%;
    position: relative;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

//quick show/hide layers
@mixin showLayer {
  position: relative;
  top: auto;
  width: 100%;
  float: left;
  pointer-events: auto;
  opacity: 1;
  z-index: 1;
}

@mixin hideLayer {
  position: absolute;
  width: 100%;
  opacity: 0;
  left: 0;
  pointer-events: none;
  z-index: -5;
}

//flex layouts
@mixin flexCenter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: bp(sm)) {
    flex-direction: column;
  }
}

.flex-center {
  @include flexCenter;

  .col {
    width: 100%;
    flex: 1 1 auto;
  }
}

//ui basics
@mixin internalNav {
  @include flexCenter;
}

@mixin internalNavLink {
  width: 100%;
  flex: 1 1 auto;
  text-align: center;
}
