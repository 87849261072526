/****************************************

TAB SECTION
Basic show/hide content area with navigation

include js: 
tabSections()

basic structure: 
<div class="tab-section" data-tab-section>
    <div class="tab-nav">
        <div class="tab-link active" data-tab-target="0">Tab 0</div>
        <div class="tab-link" data-tab-target="1">Tab 1</div>
    </div>
    <div class="tab-cont">
        <div class="tab-item active" data-tab="0">Tab 0 Content</div>
        <div class="tab-item" data-tab="1">Tab 1 Content</div>
    </div>
</div>

optional mobile:
add "mobile-active" to tab deired to be open on mobile 

****************************************/

.tab-section {
  .tab-nav {
    @include flexCenter;
    @include internalNav;
    position: relative;
    justify-content: flex-start;
    font-size: fontsize(p);
    margin-top: 20px;

    .tab-link {
      padding: 2px 10px 2px 0;
      margin-right: 10px;
      cursor: pointer;
      flex: none;
      color: color(grey);
      transition: all 0.5s;

      &:not(:last-of-type) {
        border-right: 1px solid color(grey, bg);
      }

      &.active {
        color: color(accent);
      }
    }
  }

  .tab-cont {
    position: relative;

    .tab-item {
      @include hideLayer;

      &.active {
        @include showLayer;
      }

      @media (max-width: bp(sm)) {
        &.mobile-active {
          @include showLayer;
        }
      }
    }
  }
}
