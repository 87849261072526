/****************************************

SIDEBAR

****************************************/
.sidebar {
  width: 350px;
  padding: 20px;
  min-height: calc(100vh - #{$headerHeight});
  background: color(grey, bg);
  float: left;

  .profile-preview {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    .avatar {
      width: 75px;
      height: 75px;
      background-size: cover;
      background-position: center center;
      border-radius: 50%;
      margin-right: 10px;

      &.placeholder {
        border: 2px solid color(accent);
      }
    }

    .inner {
      p {
        margin: 0;
        line-height: 1;

        &.name {
          font-size: fontsize(h2);
          margin-bottom: 5px;
        }

        &.co {
          font-size: fontsize(h3);
        }
      }
    }
  }

  ul {
    padding: 0;

    li {
      a {
        padding: 5px 15px;
        margin-bottom: 3px;
        border-radius: $borderRadius;
        width: 100%;
        display: flex;
        transition: all 0.5s;
      }

      &.active {
        a {
          background-color: color(accent);
          color: color(white);
        }
      }
    }
  }
}
