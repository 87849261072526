form {
  .form-group {
    &:not(:last-of-type) {
      border-bottom: 3px solid color(grey, bg);
    }
  }
}

input,
select {
  width: 100%;
  padding: 20px 0;
  font-size: fontsize(p);
  font-family: $headlineFont;
  border: none;

  &::placeholder {
    font-style: italic;
  }
}

select {
  height: 30px;
  border-radius: $borderRadius;
  border: 1px solid color(black);
  color: color(black);
  font-family: $headlineFont;
  width: auto;
  margin: 20px 0;
  padding: 0;
}
