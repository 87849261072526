.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 50px;
  color: color(white);
  min-height: $headerHeight;

  h1 {
    text-align: left;
    flex: 1;
    padding: 0 10px;
  }

  .logo {
    width: $leftSpacing;
    height: auto;
    position: absolute;
    left: 0;
    top: 20px;
    padding: 10px;
    border-right: color(accent, blue) 2px solid;

    img {
      width: 100%;
      height: auto;
    }
  }

  ul {
    display: flex;
    position: absolute;
    top: 10px;
    right: 10px;

    li {
      padding: 0;

      a {
        color: color(accent);
        border-radius: $borderRadius;
        padding: 3px 10px;
        margin-left: 5px;
      }

      &.map-link {
        margin-right: 15px;
        a {
          text-transform: uppercase;
          color: color(white);
          background-color: color(accent);
        }
      }
    }
  }
}

.map-view .topbar {
  border-bottom: color(accent, blue) 2px solid;
}
