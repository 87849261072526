/****************************************

GLOBAL SETTINGS

****************************************/
html,
body {
  font-family: $bodyFont;
  font-size: 10px;
}

main {
  min-height: 800px;
  width: 100%;
  font-size: fontsize(p);
}

h1,
p.title1 {
  font-size: fontsize(h1);
  font-weight: normal;
  line-height: 1;
  font-family: $headlineFont;
}

h2,
p.title2 {
  font-size: fontsize(h2);
  font-weight: 100;
  font-weight: normal;
  line-height: 1;
  font-family: $headlineFont;
}

h3,
p.title3 {
  font-size: fontsize(h3);
  font-weight: 100;
  font-weight: normal;
  line-height: 1;
  font-family: $headlineFont;
  text-transform: uppercase;
}

p,
ul {
  font-size: fontsize(p);
  line-height: 1.5;

  sup {
    vertical-align: baseline;
    position: relative;
    top: -0.4em;
  }

  strong {
    font-weight: 600;
  }
}

.btn,
.btn-cont a {
  padding: 7px 20px;
  margin-right: 10px;
  border-radius: $borderRadius;
  font-size: fontsize(p);
  color: color(white) !important;
  background: color(accent);
  border: none;
  text-decoration: none;
  text-align: center;
  display: block;
  cursor: pointer;
  float: left;
  transition: all 0.5s;

  a {
    color: color(white);
    text-decoration: none;
  }

  &:hover {
  }

  &.red {
    background-color: color(accent, error);
  }
}

.btn-cont {
  padding: 0;
}

a {
  text-decoration: none;
  color: color(black);
}

@mixin dropDownStyle {
  height: 30px;
  border: 1px solid color(accent);
  border-radius: $borderRadius;
  padding: 5px;
}
