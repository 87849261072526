/****************************************

ACCORDIAN SECTION

include js: 
acccordianSection()

optional setting:
data-accordian="solo" : show one item only

basic structure: 
<div class="accordian-section" data-accordian="solo">
    <div class="accordian-item" data-accordian-item>
        <div class="accordian-header" data-accordian-header>
            <div class="accordian-icon">
                <span data-accordian-open>+</span>
                <span data-accordian-close>-</span>
            </div>
            Accordian A
        </div>
        <div class="accordian-details">
            <p>Accordian A - Content</p>
        </div>
    </div>
  </div>
</div>

****************************************/

.accordian-section {
  .accordian-item {
    .accordian-header {
      display: flex;
      align-items: center;
      min-height: 30px;
      cursor: pointer;
      border-bottom: 1px solid #000;

      .accordian-icon {
        min-width: 15px;

        span[data-accordian-open] {
          @include showLayer;
        }

        span[data-accordian-close] {
          @include hideLayer;
        }
      }
    }

    .accordian-details {
      width: 100%;
      max-height: 0px;
      overflow: hidden;
      padding: 0 10px;

      transition: all 0.5s;

      p {
        min-height: 100px;
      }
    }

    &.active {
      .accordian-header .accordian-icon {
        span[data-accordian-open] {
          @include hideLayer;
        }

        span[data-accordian-close] {
          @include showLayer;
        }
      }

      .accordian-details {
        max-height: 500px;
        padding: 10px;
      }
    }
  }
}
