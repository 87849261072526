.filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 0px 7px 10px;

  @media (max-width: bp(tablet)) {
    flex-direction: column;
    padding: 10px;
  }

  .dates {
    .react-date-picker {
      margin-right: 10px;
    }
  }

  .icon {
    width: 30px;
    height: auto;
  }

  .count {
    width: 80px;

    @media (max-width: 1200px) {
      display: none;
    }
  }

  .add-remove {
    display: flex;

    .add,
    .delete {
      width: 30px;
      height: 30px;
      color: color(white);
      background: color(grey);
      text-align: center;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;
      margin-right: 10px;

      span {
        font-weight: bold;
        font-size: 24px;
        line-height: 0;
      }

      &:hover {
        .subnav {
          max-height: 400px;
          padding: 5px;
        }
      }

      .subnav {
        position: absolute;
        width: 150px;
        max-height: 0px;
        background: color(grey);
        display: flex;
        flex-direction: column;
        padding: 0 5px;
        top: 32px;
        right: 0px;
        z-index: 99;
        overflow: hidden;
        transition: all 0.5s;

        .desc {
          text-transform: uppercase;
          text-align: center;
        }

        div {
          text-align: left;
          padding: 4px;
        }
      }
    }
  }

  &.purple {
    .cb {
      input[type="checkbox"]:checked + label {
        &:before,
        &:after {
          background-color: color(accent, purple);
          border-color: color(accent, purple);
        }
      }
    }
  }

  &.gray {
    .cb {
      input[type="checkbox"]:checked + label {
        &:before,
        &:after {
          background-color: color(accent, gray);
          border-color: color(accent, gray);
        }
      }
    }
  }

  &.green {
    .cb {
      input[type="checkbox"]:checked + label {
        &:before,
        &:after {
          background-color: color(accent, green);
          border-color: color(accent, green);
        }
      }
    }
  }

  &.blue {
    .cb {
      input[type="checkbox"]:checked + label {
        &:before,
        &:after {
          background-color: color(accent, blue);
          border-color: color(accent, blue);
        }
      }
    }
  }
}

.sources {
  display: flex;
  flex-direction: column;
  position: relative;
  @include dropDownStyle;
  padding: 5px 10px;

  cursor: pointer;

  .title {
    padding-right: 45px;

    &:after {
      content: "";
      position: absolute;
      display: block;
      font-size: 0.7em;
      top: 8px;
      right: 10px;
      color: #fff;
      border: solid color(accent);
      border-width: 0 2px 2px 0;
      display: inline-block;
      transform: rotate(45deg);
      padding: 3px;
    }
  }

  .list {
    position: absolute;
    background: color(white);
    z-index: 9;
    top: 25px;
    left: -1px;
    margin: 0;
    max-height: 0;
    overflow: hidden;
    padding: 0px 10px;
    opacity: 0;
    width: calc(100% + 2px);
    background: color(white);
    border-bottom: 1px solid color(accent);
    border-right: 1px solid color(accent);
    border-left: 1px solid color(accent);
    border-radius: 0 0 $borderRadius $borderRadius;
    z-index: 99;

    .filter {
      display: flex;
      margin-bottom: 10px;
      padding: 0;

      input {
        padding: 5px;
        border: 1px solid color(grey, bg);
        width: 100%;
        z-index: 1;
      }

      .clear {
        font-size: 24px;
        color: color(grey);
        padding: 0 2px 0 7px;
        line-height: 1;
        position: absolute;
        z-index: 9;
        right: 15px;
      }
    }

    .cb {
      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }
  }

  &.active {
    .title {
      &:after {
        transform: rotate(180deg + 45deg);
        transition: all 0.5s;
      }
    }
    .list {
      opacity: 1;
      max-height: 500px;
      padding: 10px;
    }
  }
}
