//tab section
.tab-cont {
  min-height: 200px;
}

//swiper section
.swiper-cont {
  min-height: 200px;
  border: 1px solid #000;

  .swiper-slide {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
