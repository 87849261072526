@import "../nl-base/_nl-functions";

//fonts
$headlineFont: "Raleway", Helvetica, Arial, sans-serif;
$bodyFont: "Raleway", Helvetica, Arial, sans-serif;

//font sizes
$fontSizes: (
  h1: 2.8rem,
  h2: 2.4rem,
  h3: 1.8rem,
  p: 1.6rem,
  disclaimer: 1.2rem,
);

//Spacing
$paddingMain: 50px;
$sectionSpace: 30px;
$borderRadius: 5px;

$headerHeight: 110px;
$leftSpacing: 150px;
$rightSpacing: 100px;
$spacing: 10px;

//break points
$breakPoints: (
  xs: 400px,
  sm: 768px,
  tablet: 1024px,
  sm-desktop: 1250px,
  lg-desktop: 1350px,
);

//colors
$colors: (
  white: (
    base: #fff,
    transparent: rgba(255, 255, 255, 0.75),
  ),
  black: (
    base: #000,
  ),
  grey: (
    base: #747474,
    bg: #e8e6e6,
  ),
  accent: (
    base: #5f3b8d,
    purple: #5f3b8d,
    gray: #818486,
    green: #b1c13c,
    blue: #0073b9,
    error: #222020,
  ),
);
