.map-view {
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  font-size: fontsize(p);

  .comm-nav {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 500px;
    position: absolute;
    top: 20px;
    left: $leftSpacing + $spacing;
    z-index: 9;

    .avatar {
      width: 75px;
      height: 75px;
      background-size: cover;
      background-position: center center;
      border-radius: 50%;
      margin-right: 20px;
      margin-bottom: 10px;
      flex: none;

      &.placeholder {
        border: 2px solid color(accent);
      }
    }

    .dropdown {
      width: 300px;
    }
  }

  .map-nav {
    width: calc(100% - #{$leftSpacing + 50px});
    display: flex;
    position: absolute;
    left: $leftSpacing + $spacing;
    top: 40px;

    @media (max-width: bp(sm-desktop)) {
      flex-direction: column;
    }

    .filters {
      width: 100%;
      justify-content: flex-end;
      display: flex;
      flex-direction: column;

      p {
        padding: 5px;
      }

      .full {
        &:not(:last-of-type) {
          border-bottom: 1px solid color(black);
        }
      }
    }
  }

  .filters {
    width: 100%;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;

    &.legend {
      position: absolute;
      left: #{-$leftSpacing - $spacing};
      top: 70px;
      width: 350px;
      padding: 0 10px 10px 10px;
      z-index: 9;
      background: #fff;

      &.primary {
        width: auto;
      }

      .date {
        font-weight: bold;
      }

      .legend-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        z-index: 1;

        .count {
          display: flex;
          align-items: center;

          &:first-of-type {
            margin-right: 20px;
          }

          span {
            font-family: "Helvetica", sans-serif;
          }

          img {
            width: 22px;
            height: auto;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .react-date-picker {
    .react-date-picker__wrapper {
      @include dropDownStyle;
    }

    .react-date-picker__calendar {
      z-index: 99;
    }

    .react-date-picker__button,
    .react-date-picker__calender-button {
      display: none;
    }
  }

  .main {
    min-height: auto;
    .map-cont {
      width: 100%;
      height: calc(100vh - #{$headerHeight});

      #google-map {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.compare-controller {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  opacity: 0.3;
  pointer-events: none;
  transition: 0.5s all;

  > div {
    margin-right: 10px;
  }

  &.active {
    opacity: 1;
    pointer-events: auto;
  }

  p.title {
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 10px;

    @media (max-width: 1050px) {
      display: none;
    }
  }

  .dates,
  .compare,
  .show-hide,
  .sources {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dates {
    > div,
    > span {
      margin: 0 5px;
    }

    p.title {
      @media (max-width: bp(sm-desktop)) {
        display: none;
      }
    }
  }

  .compare {
    p.title {
      @media (max-width: bp(sm-desktop)) {
        display: none;
      }
    }
  }

  .show-hide {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    p.title {
      @media (max-width: bp(lg-desktop)) {
        display: none;
      }
    }

    span {
      font-size: font-size(disclaimer);
    }

    img {
      width: 22px;
      height: auto;
    }

    .options {
      display: flex;
      flex-direction: row;
    }

    .option {
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: all 0.5s;
      cursor: pointer;
      margin: 0 10px;

      &.inactive {
        opacity: 0.5;
      }
    }
  }
}
