.main {
  min-height: calc(100vh - #{$headerHeight});
  display: flex;

  .cont {
    width: 100%;
    padding: 50px;
  }
}

.alert {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid color(grey, bg);
  font-size: fontsize(disclaimer);
}

.avatar {
  width: 150px;
  height: auto;
}

.list {
  margin: 10px 0;

  .item {
    padding: 20px 0;
    font-size: fontsize(p);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-of-type) {
      border-bottom: 3px solid color(grey, bg);
    }

    .label {
      color: color(grey);
      width: 150px;
      margin-right: 25px;
    }

    .data {
      display: flex;
      width: 100%;
      align-items: center;

      .btn {
        margin-left: 15px;
      }
    }

    a {
      display: flex;
      flex-grow: 1;
    }

    .links {
      a {
        margin-left: 10px;
        color: color(accent);
      }
    }
  }
}

.details {
  margin: 10px 0;
  padding: 10px;
}

.action-bar {
  padding: 10px 10px 10px 10px;
  margin-top: 10px;
  font-size: fontsize(p);
  display: flex;
  justify-content: flex-end;
  border: 1px solid color(grey, bg);
}

.forgot-password {
  font-size: fontsize(disclaimer);
  float: right;
}
