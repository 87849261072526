/****************************************

FOOTER

****************************************/
footer {
  width: 100%;
  min-height: 100px;
  font-size: fontsize(p);
}
